<template>
  <div class="container">
    <div class="handle-box">
      <el-form ref="passwordResetForm" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="User Role">
          <el-select
            v-model="form.userRole"
            @change="loadData"
            placeholder="Please select..."
          >
            <el-option label="Admin" value="Admin"></el-option>
            <el-option label="Student" value="Student"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Length">
          <el-input-number v-model="form.minLength" :step="1" /> To
          <el-input-number v-model="form.maxLength" :step="1" />
        </el-form-item>
        <el-form-item label="Letter">
          <el-switch
            :inactive-value="0"
            :active-value="1"
            v-model="form.minLetters"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Capital Letter">
          <el-switch
            :inactive-value="0"
            :active-value="1"
            v-model="form.minCapitalLetters"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Special symbol">
          <el-switch
            :inactive-value="0"
            :active-value="1"
            v-model="form.minSpecialChars"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Number">
          <el-switch
            :inactive-value="0"
            :active-value="1"
            v-model="form.minNumbers"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Life(days)">
          <el-input-number v-model="form.passwordLife" :step="1" />
        </el-form-item>
      </el-form>
      <div>
        <span class="dialog-footer">
          <el-button type="primary" @click="updatePasswordRule">
            Update
          </el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/common",
      rules: {},
      form: {
        userRole: "Student",
        passwordLife: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(
        `${this.controllerUrl}/getPasswordRule/${this.form.userRole}`
      ).then((res) => {
        console.log(res);
        this.form = res.result;
      });
    },
    updatePasswordRule() {
      this.$refs.passwordResetForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/savePasswordRule`, this.form).then(
            (res) => {
              if (res.result && res.code === "200") {
                this.editVisible = false;
                this.$message.success("Update successfully");
              } else {
                this.$message.error(
                  "Update failed, error message: " + res.message
                );
              }
            }
          );
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-form-item__label {
  width: 120px;
}
</style>
