<template>
  <div class="container">
    <div class="handle-box">
      <el-form
        ref="siteConfigForm"
        :model="form"
        :rules="rules"
        label-width="120px"
      >
        <el-collapse v-model="siteConfigActiveName" accordion>
          <el-collapse-item title="SMTP" name="smtpConfig">
            <el-form-item label="From Email">
              <el-input v-model="form.smtp.from" />
            </el-form-item>
            <el-form-item label="Host">
              <el-input v-model="form.smtp.host" />
            </el-form-item>
            <el-form-item label="Port">
              <el-input-number v-model="form.smtp.port" />
            </el-form-item>
            <el-form-item label="Username">
              <el-input v-model="form.smtp.username" />
            </el-form-item>
            <el-form-item label="Password">
              <el-input v-model="form.smtp.password" :show-password="true" />
            </el-form-item>
            <el-form-item label="Delivery Method">
              <el-select
                v-model="form.smtp.deliveryMethod"
                placeholder="Please select..."
              >
                <el-option label="Network" value="Network"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Enable SSL">
              <el-switch v-model="form.smtp.enableSsl"></el-switch>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="footer">
        <el-button type="primary" @click="updateConfig">
          Update
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/common",
      siteConfigActiveName: "smtpConfig",
      rules: {},
      form: {
        smtp: {},
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/loadSiteConfig`).then((res) => {
        console.log(res);
        this.form = res.result;
      });
    },
    updateConfig() {
      this.$refs.siteConfigForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/saveSiteConfig`, this.form).then(
            (res) => {
              if (res.result && res.code === "200") {
                this.$appSetting.init();
                this.$message.success("Update successfully");
              } else {
                this.$message.error(
                  "Update failed, error message: " + res.message
                );
              }
            }
          );
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-form-item__label {
  width: 120px;
}
.footer {
  margin-top: 20px;
}
</style>
