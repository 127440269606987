<template>
  <div class="container">
    <div class="handle-box">
      <el-form
        :inline="true"
        ref="educationConfigForm"
        :model="form"
        label-width="80px"
      >
        <el-collapse v-model="educationConfigActiveName" accordion>
          <el-collapse-item
            title="Lessons' Scopes Setting"
            name="scopesSetting"
          >
            <div v-for="(item, index) in form.scopes.items" :key="index">
              <el-form-item :prop="`scopes.items.${index}.name`" label="Name">
                <el-input v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item :prop="`scopes.items.${index}.title`" label="Title">
                <el-input v-model="item.title"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('scopes', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleDeleteObjectItem('scopes', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('scopes', index, item)"
              >
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownObjectItem('scopes', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            v-if="false"
            title="Knowledge Points"
            name="knowledgePointsSetting"
          >
            <div v-for="(item, index) in form.knowledgePoints" :key="index">
              <el-form-item :prop="`knowledgePoints.${index}`" label="Name">
                <el-input v-model="form.knowledgePoints[index]"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddItem('knowledgePoints', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleDeleteItem('knowledgePoints', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpItem('knowledgePoints', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveDownItem('knowledgePoints', index, item)"
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            v-if="false"
            title="Marks Percentage Occupation Setting"
            name="marksPercentageOccupationSetting"
          >
            <div
              v-for="(item, index) in form.marksPercentageOccupation.items"
              :key="index"
            >
              <el-form-item
                :prop="`marksPercentageOccupation.items.${index}.name`"
                label="Name"
              >
                <el-input v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item
                :prop="`marksPercentageOccupation.items.${index}.percentage`"
                label="Percentage"
              >
                <el-input v-model="item.percentage"></el-input>
              </el-form-item>
              <el-button
                size="mini"
                @click="
                  handleAddObjectItem('marksPercentageOccupation', index, item)
                "
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="
                  handleDeleteObjectItem(
                    'marksPercentageOccupation',
                    index,
                    item
                  )
                "
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="
                  handleMoveUpObjectItem(
                    'marksPercentageOccupation',
                    index,
                    item
                  )
                "
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="
                  handleMoveDownObjectItem(
                    'marksPercentageOccupation',
                    index,
                    item
                  )
                "
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            title="Points Template Setting"
            name="pointsTemplatesSetting"
          >
            <div
              v-for="(item, index) in form.pointsTemplates.items"
              :key="index"
            >
              <el-form-item
                :prop="`pointsTemplates.items.${index}.name`"
                label="Name"
              >
                <el-input
                  :readonly="item.predefine"
                  v-model="item.name"
                  style="width:140px;"
                ></el-input>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.title`"
                label="Title"
              >
                <el-input v-model="item.title" style="width:140px;"></el-input>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.points`"
                label="Points"
              >
                <el-input v-model="item.points" style="width:80px;"></el-input>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.issuedType`"
                label=""
              >
                <el-select
                  v-model="item.issuedType"
                  placeholder="Issued Type..."
                >
                  <el-option label="Normal" value="Normal"></el-option>
                  <el-option label="Pending" value="Pending"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="`pointsTemplates.items.${index}.predefine`"
                label="Predefine"
              >
                <el-checkbox
                  :disabled="item.predefine"
                  v-model="item.predefine"
                ></el-checkbox>
              </el-form-item>
              <el-button
                size="mini"
                @click="handleAddObjectItem('pointsTemplates', index, item)"
              >
                <el-icon>
                  <plus />
                </el-icon>
              </el-button>
              <el-button
                v-if="!item.predefine"
                size="mini"
                @click="handleDeleteObjectItem('pointsTemplates', index, item)"
              >
                <el-icon>
                  <delete />
                </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="handleMoveUpObjectItem('pointsTemplates', index, item)"
              >
                <el-icon> <top /> </el-icon>
              </el-button>
              <el-button
                size="mini"
                @click="
                  handleMoveDownObjectItem('pointsTemplates', index, item)
                "
              >
                <el-icon> <bottom /> </el-icon>
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>

      <div class="footer">
        <el-button type="primary" @click="updateConfig">
          Update
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/common",
      educationConfigActiveName: "scopesSetting",
      rules: {},
      form: {
        knowledgePoints: [],
        scopes: {
          items: [],
        },
        marksPercentageOccupation: {
          items: [],
        },
        pointsTemplates: {
          items: [],
        },
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/loadEducationConfig`).then((res) => {
        console.log(res);
        this.form = res.result;
        if (this.form.knowledgePoints.length == 0) {
          this.form.knowledgePoints = [""];
        }
        if (this.form.scopes.items.length == 0) {
          this.form.scopes.items = [{}];
        }
        if (this.form.marksPercentageOccupation.items.length == 0) {
          this.form.marksPercentageOccupation.items = [{}];
        }
        if (this.form.pointsTemplates.items.length == 0) {
          this.form.pointsTemplates.items = [{}];
        }
      });
    },
    updateConfig() {
      this.$refs.educationConfigForm.validate((valid) => {
        if (valid) {
          postData(`${this.controllerUrl}/saveEducationConfig`, this.form).then(
            (res) => {
              if (res.result && res.code === "200") {
                this.$appSetting.init();
                this.$message.success("Update successfully");
              } else {
                this.$message.error(
                  "Update failed, error message: " + res.message
                );
              }
            }
          );
        } else {
          this.$message.error("Validation failed, please correct!");
          return false;
        }
      });
    },
    handleAddObjectItem(name, index) {
      this.form[name].items.splice(index + 1, 0, {});
    },
    handleDeleteObjectItem(name, index) {
      this.form[name].items.splice(index, 1);
    },
    handleMoveUpObjectItem(name, index) {
      if (index <= 0) return;
      let delItems = this.form[name].items.splice(index, 1);
      this.form[name].items.splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownObjectItem(name, index) {
      if (index >= this.form[name].items.length - 1) return;
      let delItems = this.form[name].items.splice(index, 1);
      this.form[name].items.splice(index + 1, 0, delItems[0]);
    },

    handleAddItem(name, index) {
      this.form[name].splice(index + 1, 0, "");
    },
    handleDeleteItem(name, index) {
      this.form[name].splice(index, 1);
    },
    handleMoveUpItem(name, index) {
      if (index <= 0) return;
      let delItems = this.form[name].splice(index, 1);
      this.form[name].splice(index - 1, 0, delItems[0]);
    },
    handleMoveDownItem(name, index) {
      if (index >= this.form[name].length - 1) return;
      let delItems = this.form[name].splice(index, 1);
      this.form[name].splice(index + 1, 0, delItems[0]);
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.el-form-item__label {
  width: 120px;
}
.footer {
  margin-top: 20px;
}
</style>
