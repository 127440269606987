<template>
  <el-form
    ref="mainForm"
    :model="form"
    :rules="rules"
    :lazy="true"
    label-width="200px"
  >
    <el-tabs v-model="activeName" :lazy="true">
      <el-tab-pane label="Password Rule Setting" name="passwordRuleSetting">
        <password-rule-setting />
      </el-tab-pane>
      <el-tab-pane label="Site Setting" name="siteSetting">
        <site-config-setting />
      </el-tab-pane>
      <el-tab-pane label="Education Setting" name="educationSetting">
        <education-config-setting />
      </el-tab-pane>
      <el-tab-pane
        v-if="false"
        label="AASpecial Setting"
        name="aaSpecialSetting"
      >
        <AASpecialSetting />
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>
<script>
import PasswordRuleSetting from "./PasswordRuleSetting.vue";
import SiteConfigSetting from "./SiteConfigSetting.vue";
import EducationConfigSetting from "./EducationConfigSetting.vue";
import AASpecialSetting from "./AASpecialSetting.vue";

export default {
  components: {
    PasswordRuleSetting,
    SiteConfigSetting,
    EducationConfigSetting,
    AASpecialSetting,
  },
  data() {
    return {
      activeName: "passwordRuleSetting",
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.buttons {
  text-align: center;
}
</style>
